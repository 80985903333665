$mainColor: rgb(0, 0, 0);
$mainColorLight: rgba(0, 0, 0, 0.3);


$mainGreen: rgb(24, 78, 9);
$mainGreenLight: rgba(38, 130, 12, 0.75);


$mainRed: rgb(228, 26, 26);
$mainRedLight: rgba(239, 34, 34, 0.5);

$mainGray: hsla(0, 0%, 84%, 0.85);

$desktopWidth: 1124px;
@mixin desktop {
  @media (min-width: #{$desktopWidth}) {
    @content;
  }
}

$phoneWidth: 460px;
@mixin phone {
  @media (max-width: #{$phoneWidth}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$phoneWidth + 1}) and (max-width: #{($desktopWidth - 1)}) {
    @content;
  }
}

.card-header{
  background-color: 33,37,41, 0.5;
}