@import "../../../global.scss";

.editOrderModal {
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-dialog {
    max-width: 100vw;
    width: 98vw;
    height: 98vh;
    margin: 1vh 1vw;

    .modal-content {
      .modal-body {
        min-height: 98vh;

        .modal-header {
          padding: 0.25rem;
          display: flex;
          justify-content: start !important;
        }
        .modal-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0.5rem 0.75rem;

          .orderNumber {
            font-size: 1.7rem;
            color: $mainColor;
            font-weight: 700;
          }

          .buttons {
            display: flex;
            .button {
              padding: 0.25rem;
              border: 2px solid $mainColor;
              color: $mainColor;
              border-radius: 5px;
              width: 5.5rem;
              text-align: center;
              margin: 0 0.5rem;
              font-weight: 800;
              font-size: 1rem;
            }
            .button.disabled {
              background-color: $mainGray;
              color: gray;
              border: 2px solid gray;
            }
            .Active {
              background-color: $mainColor;
              color: white;
            }
            #cancel {
              color: red;
              border: red 2px solid;
            }
            #charge {
              color: orange;
              border: 2px solid orange;
            }
            #void {
              color: orange;
              border: 2px solid orange;
            }
          }
        }

        .editOrder {
          display: flex;
          margin-top: 0.5rem;
          overflow-y: auto;
          max-height: 76vh;

          .item {
            border-top: dashed 1px rgb(141, 141, 141);
          }

          .item:first-child {
            border-top: none;
          }

          .reward_points {
            font-weight: 700;
            font-size: 1.4rem;
            display: flex;
            justify-content: center;
          }

          .left {
            flex: 5;
            overflow: auto;
            max-height: 38rem;
            padding-top: 0.5rem;
            margin-right: 0.5rem;
            overflow-y: auto;

            .order_item {
              display: flex;
              justify-content: space-between;
              margin-bottom: 0.5rem;
              padding-bottom: 0.5rem;
              border-bottom: 1px dashed black;

              .item_name {
                .item_name_container {
                  display: flex;
                  .quantity {
                    margin-right: 1rem;
                    font-size: 0.9rem;
                    font-weight: 800;
                  }
                  .name {
                    text-align: start;
                    font-size: 1rem;
                  }
                  .size {
                    margin-left: 0.5rem;
                    text-align: start;
                    font-size: 0.9rem;
                  }
                }

                .extra_name {
                  margin-left: 2.5rem;
                  font-size: 0.75rem;
                }
                .request {
                  color: $mainColor;
                }
              }

              .item_price {
                .price {
                  text-align: end;
                  font-size: 0.9rem;

                  .old_price{
                    font-size: 0.8rem;
                    margin-right: 0.2rem;
                    color: gray;
                  }
                }
                .extra_price {
                  text-align: end;
                  font-size: 0.8rem;

                  .old_price{
                    font-size: 0.7rem;
                    margin-right: 0.2rem;
                    color: gray;
                  }
                }
                .price_input {
                  border: none;
                  border-bottom: 1px solid black;
                  text-align: end;
                  font-size: 1.3rem;
                  width: 6rem;
                }
                .edited_price {
                  font-size: 1.5rem;
                  color: red;
                  font-weight: 800;
                }
                .old_price {
                  font-size: 1.2rem;
                  text-align: end;
                }
              }

              .void_items {
                align-items: center;

                .void_button{
                  padding: 0.25rem 0.75rem;
                  background-color: $mainGray;
                  border: 1px solid gray;
                  color: gray;
                  margin-right: 0.5rem;
                  border-radius: 5px;
                }
                .Active{
                  background-color: $mainColor;
                  color: white;

                }
                // .checkbox {
                //   height: 1.4rem;
                //   width: 1.4rem;
                //   margin: 0.75rem 0;
                //   margin-right: 1rem;
                // }
              }
            }
            .order_item:last-child {
              border-bottom: none;
            }
          }

          .right {
            flex: 3;
            overflow-y: auto;

            .desc {
              font-size: 0.9rem;

              .edited {
                font-size: 1.1rem;
                color: red;
              }
            }

            .card {
              .card-header {
                font-size: 1rem;
                font-weight: 800;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0.25rem;

                .discount {
                  font-size: 0.8rem;
                  margin-right: 0.5rem;
                  border: 2px solid $mainGreen;
                  padding: 0.1rem 0.5rem;
                  border-radius: 5px;
                  background-color: $mainGreenLight;
                  color: white;
                }
              }
              .card-body {
                padding: 0.3rem;

                .payLater {
                  border-bottom: 1px solid #000;
                  font-size: 32px;
                  color: blue;
                  text-align: center;
                }
                .user_details {
                  padding-bottom: 0.2rem;
                  margin-bottom: 0.2rem;
                  border-bottom: 1px solid black;
                }
                .order_details {
                  padding-bottom: 0.2rem;
                  margin-bottom: 0.2rem;
                  border-bottom: 1px solid black;
                }
                .order_time {
                  .orderType {
                    color: red;
                    font-size: 1.3rem;
                    font-weight: 800;
                  }
                }
              }
            }

            .textbox {
              display: flex;
              flex-direction: column;
              width: 34vw;
              margin-top: 1rem;

              textarea {
                flex: 3;
              }
            }
          }
        }
      }
    }
  }
}

@include desktop {
  .editOrderModal {
    .card {
      .card-header {
        font-size: 1.4rem !important;
        padding: 0.5rem !important;

        .discount {
          font-size: 1.1rem !important;
        }
      }

      .card-body{
        padding: 0.75rem !important;
        padding-top: 0.25rem !important;
        
        .payLater {
          font-size: 36px;
        }
        .user_details {
          padding-bottom: 0.25rem !important;
          margin-bottom: 0.25rem !important;
        }
        .order_details {
          padding-bottom: 0.25rem !important;
          margin-bottom: 0.25rem !important;
        }
        .order_time {
          .orderType {
            font-size: 1.7rem !important;
          }
        }
      }
    }

    .modal-title {
      .orderNumber {
        font-size: 2em !important;
      }

      .buttons {
        .button {
          width: 8rem !important;
          font-size: 1.4rem !important;
          padding: 0.75rem 1rem !important;
        }
      }
    }

    .editOrder{
      height: 94vh !important;

      .left{
        .order_item{
          .item_name{
            .item_name_container {
              .quantity {
                font-size: 1.4rem !important;
              }
              .name {
                font-size: 1.4rem !important;
              }
              .size {
                font-size: 1.3rem !important;
              }
            }
          }
          .extra_name {
            margin-left: 2.5rem !important;
            font-size: 1.2rem !important;
          }
          .item_price {
            .price {
              font-size: 1.4rem !important;

              .old_price{
                font-size: 1rem !important;
              }
            }
            .extra_price {
              font-size: 1.2rem !important;

              .old_price{
                font-size: 0.9rem !important;
              }
            }
            .price_input {
              font-size: 1.3rem !important;
            }
            .edited_price {
              font-size: 1.5rem !important;
            }
            .old_price {
              font-size: 1.2rem !important;
            }
          }
        }
      }

      .right {
        .desc {
          font-size: 1.15rem !important;
  
          .edited {
            font-size: 1.4rem !important;
          }
        }
      }
    }
    
  }
}

@include phone {
  .editOrderModal {
    .modal-title{
      flex-direction: column !important;

      .buttons{
        display: none !important;
      }
    }

    .modal-body{
      .editOrder{
        flex-direction: column !important;
        overflow: none !important;
        max-height: none !important;

        .left{
          margin-right: 0 !important;
          margin-bottom: 0.5rem;
          max-height: 60vh !important;
          overflow: none !important;
        }

        .right{
          display: flex !important;
          flex-direction: column-reverse !important;
          overflow: none !important;
        }
      }
    }
  }
}
