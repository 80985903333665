@import "../../global.scss";

.order-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .orderType {
    width: 96%;

    .card-header {
      align-items: center;
      background-color: $mainGray;
      display: flex;
      justify-content: space-between;
      border: 1px solid gray;

      .orderType_choice {
        display: flex;
        align-items: center;
      }
      .checked {
        color: #257110;
        margin-right: 0.5rem;
      }

      .orderType_header {
        font-size: 20px;
        font-weight: 600;
      }

      svg {
        margin-right: 0.5rem;
        height: 28px;
        width: 28px;
      }
      .collapsed {
        transform: rotate(180deg);
      }
    }

    #orderType_body {
      padding: 0.5rem;
      border: 1px solid gray;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;

      .order_options {
        width: 100%;

        .option {
          display: flex;
          justify-content: center;
          align-items: center;

          button {
            margin: 0.2rem;
            background-color: #fff;
            border: 2px double #000;
            border-radius: 10px;
            font-size: 18px;
            padding: 0.6rem 0;
            width: 36%;
            color: black;
          }
          .onlyPickup {
            width: 74%;
          }
          .Active {
            background-color: $mainRedLight;
            border: 4px solid $mainRed;
            color: #fff;
            font-weight: 600;
          }
        }
      }

      .laterTime_container {
        padding-top: 0.5rem;
        margin-top: 0.5rem;
        border-top: 1px solid rgb(168, 168, 168);

        .laterTime_header {
          font-size: 18px;
          font-weight: 700;
          padding: 0.5rem 0;
        }
        .laterTime_choices {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: column;

          .dropdown-toggle {
            align-items: center;
            display: flex;
            font-size: 1.05rem;
            height: 2.5rem;
            justify-content: space-between;
            padding: 0 5px;
            background: $mainGray;
            color: black;
          }
          .dropdown-menu {
            max-height: 500px;
            overflow: auto;
            padding: 0 0.6rem;
            width: 90vw !important;

            li {
              padding: 0;
              border-bottom: 1px solid rgb(156, 156, 156);
            }
            .dropdown-item {
              padding: 0.6rem 1rem;
              font-size: 0.9rem;
            }
          }
          .dropdown_date {
            margin-bottom: 0.5rem;
            .dropdown-toggle {
              width: 40vw;
            }
          }

          .dropdown_time {
            .dropdown-toggle {
              width: 40vw;
            }
            .dropdown-item {
              padding: 0.6rem 1rem;
              font-size: 0.9rem;
            }
          }
        }
      }

      .addresses_container {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 0.5rem;

        .delivery_range {
          margin-bottom: 0.5rem;
          font-size: 0.85rem;
          border: 1px solid gray;
          border-radius: 5px;
          padding: 4px;
          background-color: $mainGray;
        }

        .dropdown-toggle {
          align-items: center;
          display: flex;
          font-size: 0.8rem;
          height: 2.75rem;
          justify-content: space-between;
          margin-top: 10px;
          width: 80vw;
          background: $mainGray;
          color: black;
        }
        .dropdown-menu {
          max-height: 300px;
          overflow: auto;
          padding: 0 0.5rem;
          width: 90%;

          li {
            padding: 0;
            padding: 1rem 0;
            border-bottom: 1px solid rgb(156, 156, 156);
          }
          .dropdown-item {
            padding: 0;
            font-size: 0.8rem;
          }
        }

        .add_address {
          border-top: 1px solid hsla(0, 0%, 73%, 0.5);
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px;
          width: 100%;

          .add_addressTitle {
            font-size: 18px;
            font-weight: 600;
          }

          svg {
            height: 26px;
            width: 26px;
          }
        }

        .google_maps_input_container {
          margin: 0.75rem auto;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          border: 1px solid gray;
          border-radius: 5px;
          background-color: rgba(244, 244, 244, 0.7);

          svg {
            height: 20px;
          }

          input {
            width: 30vw;
            border: none;
            padding: 0.6rem 0.3rem;
            font-size: 0.75rem;
            background-color: rgba(244, 244, 244, 0.7);
            border-left: 1px solid gray;
          }
        }
      }
    }
  }

  .tipOption {
    width: 96%;

    .card-header {
      align-items: center;
      background-color: $mainGray;
      display: flex;
      justify-content: space-between;
      border: 1px solid gray;

      .tipOption_choice {
        display: flex;
        align-items: center;
      }
      .checked {
        color: #257110;
        margin-right: 0.5rem;
      }
      .tipOption_header {
        font-size: 20px;
        font-weight: 600;
      }
      svg {
        margin-right: 0.5rem;
        height: 28px;
        width: 28px;
      }
      .collapsed {
        transform: rotate(180deg);
      }
    }
    #tipOption_body {
      padding: 0.5rem 0.5rem;
    }
    .card-body {
      border: 1px solid gray;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;

      .tipOption_container {
        display: flex;
        align-items: center;
        flex-direction: column;

        .tip_options {
          width: 100%;
          margin-bottom: 0.5rem;
          display: flex;
          justify-content: space-around;
          align-items: center;
          flex-direction: column;

          .buttons_tip{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            flex-wrap: wrap;

            .tips{
              display: flex;
              margin: 0.25rem 0;
            }
            .other_tips{
              .tip_button{
                padding: 6px 14px;
              }
            }

            .tip_button{
              background-color: $mainGray;
              font-size: 0.8rem;
              padding: 6px 4px;
              margin: 0 0.2rem;
              border-radius: 5px;
              text-align: center;
              border: 1px solid gray;
            }
            .Active {
              background-color: $mainRedLight;
              border: $mainRed 2px solid;
              color: white;
              font-weight: 700;
            }
          }

          .tip_amount {
            font-size: 22px;
            font-weight: 600;
          }
          .dropdown_tip {
            .dropdown-toggle {
              width: 36vw;
              height: 3.5rem;
              display: flex;
              justify-content: space-between;
              align-items: center;
              background: $mainGray;
              color: black;
            }

            .dropdown-menu {
              width: 48vw;
              padding: 0 0.5rem;

              li {
                border-bottom: 1px solid rgba(185, 185, 185, 0.5);

                .dropdown-item {
                  padding: 0.75rem 1rem;
                }
              }
            }
          }
        }
        .tip_customAmount {
          padding: 0.25rem;
          display: flex;
          flex-direction: row;
          justify-content: space-around;

          div {
            display: flex;
            align-items: flex-end;
          }

          .customTip_input {
            border: none;
            border-bottom: 1px inset gray;
            width: 45vw;
            height: 2rem;
            font-size: 18px;
            padding: 0 10px;
          }
          .customTip_input:hover {
            border: none;
            box-shadow: 0 6px 3px -3px $mainColor;
          }
        }
        .tip_info {
          width: 82%;
          text-align: center;
        }
      }
    }
  }

  .orderDetails {
    width: 96%;

    .card-header {
      align-items: center;
      background-color: $mainGray;
      display: flex;
      justify-content: space-between;
      border: 1px solid gray;

      .orderPage_header {
        font-size: 20px;
        font-weight: 600;
      }
    }

    .card-body {
      border: 1px solid gray;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;

      .details_orderItems {
        display: flex;
        flex-direction: column;
        width: 100vw;
        padding: 0;    
        border-bottom: 2px solid rgba(185, 185, 185, 0.875);

        .unavailable {
          background-color: #eee;
          margin: 0.25rem auto;
          margin-top: 0;
          padding: 0.25rem 0.5rem;
          font-size: 0.75rem;
          display: flex;
          flex-direction: row;
          align-items: center;

          .cash_in_store {
            display: block;
          }
        }
        
        .checkbox {
          font-size: 1.5rem;
          margin-right: 0.5rem;
        }
        .green-check {
          color: #257110;
        }
        svg {
          width: 40px;
        } 

        .orderPage_item {
          display: flex;
          flex-direction: row;
          border-top: 2px solid rgba(185, 185, 185, 0.875);
          padding: 0.5rem 0rem;

          .orderPage_itemQuantity {
            flex: 1;
            font-size: 17px;
          }

          .orderPage_itemDetails {
            flex: 8;
            padding-right: 0.5rem;
            font-size: 17px;

            .orderPage_itemPizzaHalf {
              font-size: 14px;
            }
          }

          .orderPage_itemTotalContainer {
            .orderPage_itemTotal {
              display: flex;
              flex: 3;
              justify-content: flex-end;
              font-size: 17px;
            }
            .blank {
              color: white !important;
            }
          }
        }
        .orderPage_item:last-child {
          border-bottom: none;
        }
      }

      .details_orderCharges {
        display: flex;
        flex-direction: column;
        width: 100vw;
        padding: 0.5rem;

        .details_charges {
          display: flex;
          padding: 0.5rem 0;
          font-size: 1.2rem;

          .details_chargeType {
            flex: 3.5;
          }
          .details_price {
            display: flex;
            justify-content: flex-end;
            flex: 2;
            font-weight: 700;
          }
          .discount {
            display: flex;
            justify-content: center;
            flex: 2.5;
            div {
              font-weight: 700;
            }
          }
          .details_price_edited {
            display: flex;
            justify-content: flex-end;
            flex: 1;
            margin-left: 0.5rem;
          }
        }

        .details_finalCharge {
          display: flex;
          padding: 1.25rem 0;
          border-top: 1px solid rgba(185, 185, 185, 0.5);

          .details_chargeType {
            flex: 4;
          }
          .details_price {
            display: flex;
            justify-content: flex-end;
            flex: 2;
            font-weight: 700;
          }
        }

        .saving_container {
          background-color: $mainGray;
          border: 1px solid gray;
          border-radius: 5px;
          width: 76%;
          margin: auto;
          padding: 0.5rem 0;
        }
      }
    }
  }

  .checkout {
    width: 96%;
    margin-bottom: 3rem !important;
    .card-header {
      align-items: center;
      background-color: $mainGray;
      display: flex;
      justify-content: space-between;
      border: 1px solid gray;

      .orderPage_header {
        font-size: 20px;
        font-weight: 600;
      }
    }

    .card-body {
      border: 1px solid gray;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;

      .payment_profile {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border: 1px solid black;
        border-radius: 5px;
        padding: 0.75rem 1.5rem;
        margin: 0.75rem 0;
        background-color: $mainGray;

        div {
          font-size: 18px;
          font-weight: 700;
        }
      }
      .Active {
        background-color: $mainRedLight;
        border: 4px solid $mainRed;
        color: white;

        div {
          font-size: 20px !important;
        }
      }

      .AddPaymentMethod {
        padding: 0.5rem 0 1rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100vw;

        .payment_inputs {
          display: flex;
          flex-direction: column;
          width: 100%;

          label {
            transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1),
              opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1);
          }

          input {
            margin-bottom: 1rem;
            box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03),
              0px 3px 6px rgba(0, 0, 0, 0.02);
            border: 1px solid #e6e6e6;
            padding: 0.5rem;
            border-radius: 5px;
          }
        }
        .disclosure {
          font-size: 14px;
          color: gray;
          padding: 0 2rem;
          margin-bottom: 0.5rem;
        }
      }
      .add_payment_method {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid rgba(185, 185, 185, 0.5);
        border-bottom: 1px solid rgba(185, 185, 185, 0.5);
        padding: 10px;

        .add_paymentMethodTitle {
          margin-bottom: 0;
          font-size: 20px;
          font-weight: 600;
        }

        .cash_only {
          background-color: $mainColorLight;
          padding: 0.2rem;
          border-radius: 5px;
          border: gray 1px solid;
          color: rgb(91, 90, 90);
          margin: 0 0.5rem;
          margin-right: 1.5rem;
          display: flex;
          justify-content: center;
          width: 30%;
        }

        svg {
          height: 26px;
          width: 26px;
        }

        .pay_later_checkbox {
          height: 24px;
          width: 24px;
        }
      }
      #submit {
        align-items: center;
        background-color: $mainGreenLight;
        border-radius: 10px;
        border: 5px solid $mainGreen;
        color: #fff;
        display: flex;
        font-size: 24px;
        font-weight: 900;
        height: 5rem;
        justify-content: center;
        margin: 2rem 0 0.5rem 0;
        width: 100%;
      }
    }
  }
}

.orderCompletion {
  height: calc(100vh - 60px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 4rem;

  .thank_you {
    font-size: 34px;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  .order_ETA {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5rem;
    padding: 1rem;

    .eta_header {
      font-size: 22px;
    }
    .time {
      font-size: 20px;
      font-weight: 600;
    }
  }
  .icons {
    width: 30vw;
    display: flex;
    justify-content: space-around;

    svg {
      height: 36px;
      width: 36px;
    }
  }
}

@include phone {

  .addresses_container {
    .dropdown-toggle {
      margin-top: 0 !important;
      width: 94% !important;
    }
  }

  .order {
    width: 100% !important;
    .order_footer {
      display: none !important;
    }
  }

  .google_maps_input_container {
    width: 94%;
    margin-top: 0 !important;
    margin-bottom: 0.25rem !important;

    input {
      width: 100% !important;
    }
  }

  .dropdown_date {
    margin-bottom: 0.5rem;
    .dropdown-toggle {
      width: 100vw !important;
    }
  }

  .dropdown_time {
    .dropdown-toggle {
      width: 100vw !important;
    }
  }

  .orderHistory_container {
    .card {
      .card-header {
        padding: 0.5rem 0.25rem !important;
        .orderNumber {
          font-size: 8px !important;
        }
        .orderType {
          font-size: 8px !important;
          margin-left: 2px !important;
        }
        .orderName {
          font-size: 8px !important;
          margin-top: 0 !important;
          margin-left: 2px !important;
        }
        .total {
          font-size: 8px !important;
          margin-top: 0 !important;
          margin-left: 2px !important;
        }
        .requestedDate {
          font-size: 8px !important;
          margin-top: 0 !important;
          margin-left: 2px !important;
        }

        .status_tag {
          padding: 0.1rem !important;
          font-size: 8px !important;
        }
      }
    }
  }

  .order-page {
    .orderPage_title{
      font-size: 1.5rem !important;
      margin: 0.45rem 0;
      font-weight: 700;
    }
    .checkout {
      .card-body {
        padding: 0.5rem;

        #submit {
          margin-top: 1rem !important;
        }
        .invalid {
          background-color: $mainGreen !important;
        }
      }
    }
    .card-header {
      padding: 0.25rem 0.5rem;
    }
    .order_options {
      .option {
        button {
          padding: 0.45rem 0 !important;
        }
      }
    }
    .laterTime_container {
      padding: 0 !important;
    }

    .tip_options {
      .tip_amount {
        font-size: 1.1rem !important;
      }
    }

    .add_addressTitle{
      font-size: 1rem !important;
    }

    .details_orderCharges {
      .details_charges {
        .details_chargeType {
          font-size: 1rem !important;
        }
        .discount {
          div {
            font-size: 1rem !important;
          }
        }
        .details_price_edited {
          font-size: 1rem !important;
        }
        .details_price {
          font-size: 1rem !important;
        }
        .saving_container {
          font-size: 1.1rem !important;
        }
      }
    }

    .add_payment_method {
      .add_paymentMethodTitle{
        font-size: 1rem !important;
      }
      .cash_only {
        font-size: 0.8rem;
      }
    }
    .AddPaymentMethod {
      input {
        margin-bottom: 0.5rem !important;
      }
    }
  }
}

@include desktop {
  .order-page {
    .orderType {
      width: 60% !important;
    }
    .tipOption {
      width: 60% !important;
    }
    .orderDetails {
      width: 60% !important;
    }
    .checkout {
      width: 60% !important;
    }
  }
}
