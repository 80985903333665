@import url("https://fonts.googleapis.com/css2?family=PT+Serif&display=swap");
@import "./global.scss";

* {
  max-width: 100%;
  font-family: Arial, sans-serif;
}

.loading_details {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: $mainGray;
  z-index: 3;
}

.store_is_not_active {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid gray;
  background-color: rgb(221, 221, 221);
  color: gray;
  text-align: center;
  font-weight: bold;
  font-size: 0.85rem;
}
.desktop {
  display: none !important;
}
.mobile {
  display: flex !important;
}
.app {
  width: 100vw;
  background-color: rgb(236, 236, 236);
  display: flex;
  flex-direction: column;

  .Toastify {
    .Toastify__toast-container {
      left: 50%;
      transform: translateX(-50%);
      bottom: 1em;
    }
  }

  main {
    min-height: calc(100vh - 60px - 170px);
    display: flex;
    flex-direction: column;
    padding: 0.25rem 0;
  }

  .store-inactive {
    margin-top: 50px;
  }

  .desktop_cart_container {
    display: none;
  }
}

.not_found {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  padding: 0rem 0.5rem;

  .store_container {
    .store_link_container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 1.5rem;

      .store {
        width: 90%;
      }
    }
  }
}

.menu_list {
  display: flex;

  .menu_list_button {
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    border-radius: 5px;
    margin-left: 0.25rem;

    svg {
      color: rgb(70, 70, 70);
      height: 30px;
      width: 30px;
    }
  }

  .menu_select_container {
    flex: 18;
  }
}

.menu_list_active {
  position: relative;
  min-height: 100vh;
  width: 100vw;
  top: 0;
  padding: 0 0.5rem;
  overflow-y: hidden;

  .menu_categories_list {
    margin-top: 0.5rem;
  }

  .category_container:first-child {
    border-top: 1px solid black;
  }

  .category_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom: 1px solid black;
    padding: 0.3rem 0;

    .cat_name {
      display: flex;
      align-items: center;
      margin-bottom: 0;
    }

    .subcats {
      .subcat_name {
        margin-bottom: 0;
        padding: 0.5rem 1rem;
        border-bottom: 1px solid black;
      }

      .subcat_name:last-child {
        border-bottom: none;
      }
    }

    img {
      display: flex;
      height: 60px;
      width: 60px;
    }
  }
}

@include desktop {
  .store_is_not_active {
    font-size: 1.5rem;
  }
  .mobile {
    display: none !important;
  }
  .desktop {
    display: flex !important;
  }

  main {
    display: flex !important;
    flex-direction: row !important;
  }

  .main_container {
    width: 64%;
    margin: 0.25rem 0.5rem;
    margin-right: 0;
  }

  .cart_popup {
    display: none !important;
  }

  .desktop_cart_container {
    display: flex !important;
    width: 36%;

    .desktop_cart {
      width: 100%;
      margin: 0.25rem 0.75rem;
      margin-left: 0;
      height: 98vh !important;
      background: white;
      position: sticky;
      top: 0.5rem;
      border: 2px solid $mainGray;
      border-radius: 8px;
      padding: 0.75rem;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .title {
        text-align: center;
        font-size: 1.6rem;
        flex: 1;
      }

      .cart_body {
        background-color: $mainGray;
        padding: 0.5rem;
        border-radius: 4px;
        flex: 23;
        display: flex;
        position: relative;
        width: 100%;

        .empty {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;

          img {
            width: 125px;
            height: 125px;
          }
        }

        .items_wrapper {
          position: absolute;
          overflow-y: auto;
          max-height: 90%;
          display: flex;
          flex-direction: column;
          flex: 12;
          width: calc(100% - 1rem);

          .cartpopup_item {
            padding: 0.5rem 0;
            margin-bottom: 0.5rem;
            padding-bottom: 0.75rem;
            border-bottom: 1px solid gray;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .left {
              flex: 11;
              display: flex;

              p {
                margin-bottom: 0;
                font-size: 14px;
              }

              .item_container {
                flex: 82%;
                display: block;

                .item {
                  display: flex;
                  align-items: center;

                  .cartpopup_itemQuantity {
                    font-size: 1rem;
                    font-weight: 700;
                  }

                  .cartpopup_itemName {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    font-size: 0.9rem;

                    .size {
                      font-size: 0.8rem;
                    }
                  }
                }

                .cartpopup_itemExtra {
                  font-size: 13px;
                  margin-left: 1rem;
                }

                .cartpopup_itemRequests {
                  font-size: 14px;
                }
              }

              .prices_container {
                width: 18%;

                .prices {
                  display: flex;
                  flex-direction: column;

                  .cartpopup_itemPrice {
                    font-size: 1rem;
                    display: flex;
                    justify-content: flex-end;
                  }

                  .cartpopup_extraPrice {
                    font-size: 13px;
                    text-align: end;
                  }

                  .empty {
                    color: rgb(230, 230, 230);
                  }
                }
              }
            }

            .right {
              flex: 1;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-end;

              .cartpopup_deleteItemButton {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 6px;
                height: 20px;
                width: 20px;

                svg {
                  height: 20px;
                  width: 20px;
                  color: $mainRed;
                }
              }
            }
          }
        }

        .checkout_container {
          z-index: 2;
          height: 10%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 0rem 0.5rem;
          border-radius: 5px;
          border: 8px double black;
          background-color: white;
          position: absolute;
          bottom: 0;
          left: 0;
          margin: 0.5rem;
          width: calc(100% - 1rem);

          .checkout_text {
            display: flex;
            align-items: center;
            justify-content: center;

            .text {
              margin-bottom: 0;
              font-size: 1.4rem;
              font-weight: 900;
              color: black;
            }

            svg {
              height: 1.5rem;
              width: 1.5rem;
              color: black;
              margin-left: 0.5rem;
            }
          }

          .total {
            margin-bottom: 0;
            font-size: 1.1rem;
            font-weight: 900;
            color: black;
          }
        }
      }
    }
  }
}

.app_home {
  min-height: calc(100vh - 100px);
  padding: 0 0;
  background-color: rgb(236, 236, 236);

  .google_maps_input_container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border: 1px solid rgb(12, 52, 108);
    border-radius: 5px;
    background-color: rgb(12, 52, 108);
    width: 550px;
    margin-right: 1rem;

    .map_pin {
      height: 26px;
      width: 26px;
      color: white;
    }

    .arrow {
      height: 36px;
      width: 36px;
      color: white;
    }

    input {
      width: 100%;
      border: none;
      padding: 0.6rem 0.3rem;
      font-size: 1.2rem;
      background-color: white;
      border-left: 1px solid rgb(12, 52, 108);
    }
  }

  .location_available {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;

    .stores_container {
      width: 1100px;
      max-width: 1100px;

      .maps_input {
        margin-bottom: 1.5rem;

        .google_maps_input_container {
          width: 700px;
          height: 60px;

          input {
            height: 100%;
            font-size: 1.6rem;
            padding-left: 1rem;
          }
        }
      }

      .store_list {
        .stores_header {
          color: rgb(12, 52, 108);
        }

        .order_link_container {
          text-decoration: none;
        }

        .store {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 220px;
          color: rgb(12, 52, 108);
          background-color: white;
          border: 1px solid rgb(12, 52, 108);
          border-radius: 5px;
          padding: 0 1rem;
          margin: 1.5rem 0;
          text-decoration: none;
          -webkit-box-shadow: 10px 12px 19px -4px rgba(107,107,107,1);
          -moz-box-shadow: 10px 12px 19px -4px rgba(107,107,107,1);
          box-shadow: 10px 12px 19px -4px rgba(107,107,107,1);
          .store_image {
            img {
              max-height: 200px;
              min-height: 200px;
              width: 300px;
              border-radius: 4px;
              object-fit: cover;
            }
          }

          .store_details {
            width: 500px;
            height: 60%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            .store_name {
              font-size: 1.75rem;
              font-weight: 700;
            }

            .store_address {
              font-size: 1.2rem;
            }

            .store_distance {
              font-size: 1.2rem;
              display: flex;
              align-items: center;

              svg {
                margin-right: 0.25rem;
              }
            }
          }

          .detail_container {
            display: flex;
            align-items: center;
          }

          .order_link {
            border: 2px solid rgb(12, 52, 108);
            border-radius: 10px;
            width: 150px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.7rem 0;
            background-color: rgba(12, 52, 108, 0.65);
            color: white;
            text-decoration: none;
            font-size: 1.2rem;
            height: 60px;
          }



          .order_link:hover {
            background-color: white;
            color: rgb(12, 52, 108);
            transition: 300ms;
          }
        }
      }
    }
  }

  .location_unavailable {
    .top {
      display: flex;
      height: calc(67vh - 50px);

      .left {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 5rem;
        padding-right: 2.5rem;

        .home_text {
          padding: 0.25rem 0;

          .text {
            font-size: 3.2rem;
            font-weight: 600;
            font-family: Georgia, sans-serif;
            letter-spacing: -1px;
            color: rgb(12, 52, 108);
          }
        }
      }

      .right {
        width: 50%;
        overflow: visible;
        position: relative;

        .home_image {
          height: 100%;
          min-width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          transform-origin: left center;
          object-fit: cover;
        }
      }
    }

    .bottom {
      background-color: white;
      height: calc(33vh - 50px);
      display: flex;
      align-items: center;
      justify-content: center;

      .text {
        width: 74%;
        font-size: 1.9rem;
        font-weight: 600;
        padding: 1rem 6rem;
        color: rgb(12, 52, 108);
        font-family: Georgia, sans-serif;
        letter-spacing: -1px;
      }
    }
  }
}

@include phone {
  .app_home {
    padding: 0 0.25rem;
  }

  .location_available {
    .stores_container {
      width: 100vw !important;

      .store {
        display: flex;
        flex-direction: column;
        min-height: 360px !important;
        padding: 0.5rem !important;
        height: auto !important;

        .store_image {
          width: 100% !important;

          img {
            width: 100% !important;
            height: auto;    /* Maintain aspect ratio */
            max-height: 180px !important;
            object-fit: cover;
          }
        }

        .detail_container {
          flex-direction: column;

          .order_link {
            width: 100% !important;
          }

          .store_details {
            margin-bottom: 0.5rem !important;

            .store_name {
              font-size: 1.5rem !important;
              margin: 0.5rem 0;
            }
            .store_address {
              font-size: 1rem !important;
            }
            .store_distance {
              font-size: 1rem !important;
            }
          }
        }
      }

      .maps_input {
        display: flex;
        justify-content: left;
        margin: 0 0.5rem;
        margin-bottom: 0.5rem !important;

        .google_maps_input_container {
          width: 100% !important;
          margin-right: 0 !important;
          height: 50px !important;

          input {
            padding: 0.25rem;
            font-size: 0.95rem !important;
          }
        }
      }

      .store_list {
        margin: 0 0.5rem;
      }
    }
  }

  .location_unavailable {
    .top {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: calc(70vh - 65px) !important;

      .left {
        width: 100% !important;
        height: calc(35vh - 45px) !important;
        padding: 1rem !important;
        
        .home_text {
          .text {
            font-size: 1.8rem !important;
          }
        }
      }

      .right {
        width: 100% !important;
        height: calc(40vh - 45px) !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .bottom {
      height: 26.5vh !important;

      .text {
        font-size: 1.2rem !important;
        width: 100% !important;
        padding: 1rem 2rem !important;
        text-align: center;
      }
    }
  }
}

@include tablet {
  .store_is_not_active {
    font-size: 1rem;
  }
  .mobile {
    display: none !important;
  }
  .desktop {
    display: flex !important;
  }

  .location_available {
    .stores_container {
      max-width: 100vw !important;
      padding: 0 1rem !important;

      .store_list {
        .store {
          justify-content: space-between !important;
          max-width: 100vw !important;

          .store_image {
            width: 30% !important;
          }

          .detail_container {
            width: 70% !important;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .store_details {
              width: auto !important;
              padding-left: 0.75rem !important;
              width: 70% !important;
            }

            .order_link {
              width: 28% !important;
            }
          }
        }
      }
    }
  }

  .location_unavailable {
    .top {
      .left {
        padding: 0 2rem !important;

        .home_text {
          .text {
            font-size: 2.2rem !important;
          }
        }
      }
    }
    .bottom {
      .text {
        font-size: 1.6rem !important;
      }
    }
  }
}