@import "../../../global.scss";

.chargeOrderModal {
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-dialog {
    max-width: 70vw;
    width: 70vw;
    margin: 10vh 15vw;

    .modal-content {
      .modal-body {
        background-color: $mainGray;

        .modal-header {
          padding: 0.25rem;
          display: flex;
          justify-content: start !important;
        }
        .modal-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0.5rem 0.75rem;
        }

        .extraCharge {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 1rem;

          label {
            font-size: 1.3rem;
            margin-bottom: 0.2rem;
          }

          .extraCharge_container {
            width: 86%;
            .charge_inputs {
              display: flex;
              flex-direction: column;
              width: 80%;
              margin-bottom: 0.5rem;
              .input {
                display: flex;
                margin-bottom: 0.5rem;
                .money {
                  padding: 6px;
                  font-size: 1.4rem;
                  background-color: white;
                  border-top-left-radius: 5px;
                  border-bottom-left-radius: 5px;
                  border: 1px solid rgb(74, 74, 74);
                }
                input {
                  padding: 6px;
                  font-size: 1.4rem;
                  border-top-right-radius: 5px;
                  border-bottom-right-radius: 5px;
                  border: 1px solid rgb(74, 74, 74);
                  width: 100px;
                }
              }
            }

            .textbox {
              textarea {
                border: 1px solid rgb(74, 74, 74);
                border-radius: 5px;
              }
            }
          }
          .submit {
            margin-bottom: 0.5rem;
            padding: 0.75rem 1.5rem;
            border-radius: 5px;
            font-weight: 700;
            font-size: 1.2rem;
            color: white;
            background-color: $mainColor;
          }
        }
      }
    }
  }
}
