.menu_items_container {
  padding: 0.5rem;

  .itemCard {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid gray;

    .itemName_container{
      display: flex;
      align-items: center;

      .item_size{
        font-size: 0.8rem;
        color: gray;
      }
    }

    .button_container {
      display: flex;
      align-items: center;

      .active_button {
        border: 1px solid black;
        border-radius: 2px;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      svg {
        height: 40px;
        width: 40px;
      }
      .Active {
        svg {
          color: green;
        }
      }

      .loading_container {
        .loading {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
  }
}

.locked {
  background-color: #eeeeee;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
  margin: 0.75rem;
  padding: 0.5rem;
  position: relative;

  .toggle-button {
    padding: 0.4rem 0.8rem;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    border-radius: 5px;
    font-weight: 700;
    margin-top: 4px;
  }

  .toggle-button.active {
    background-color: green;
    color: white;
  }

  .toggle-button.inactive {
    background-color: red;
    color: white;
  }
}

.shadow {
  position: absolute;
  background-color: rgba(80, 80, 80, 0.455);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 4;
}
