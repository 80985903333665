@import "../../global.scss";

$headings: rgb(155, 53, 39);

.terms_container {
  body {
    background-color: $mainGray;
    padding: 1.5rem;
    padding-bottom: 1rem;
  }
  
  header,
  section,
  footer {
    margin-bottom: 20px;
  }

  h2 {
    color: $headings;
  }

  p {
    color: black;
  }

  .title {
    font-size: 3rem;
  }
}
