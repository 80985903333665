@import "../../global.scss";

.rewards_page {
  min-height: calc(100vh - 60px);
  padding: 0.75rem 0.5rem;
  display: flex;
  flex-direction: column;

  .title {
    font-weight: 700;
    font-size: 1.45rem;
  }

  .notice {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0.5rem 1rem;
    margin: 0 0.25rem;
    margin-top: 0.5rem;
    font-weight: bold;
  }

  .points {
    font-size: 1.15rem;

    .my_points {
      font-weight: 600;
      font-size: 1.15rem;
    }
  }

  .reward_options_container {
    padding: 0.5rem 0.25rem;

    .unlock {
      color: green;
    }

    .lock {
      color: red;
    }

    .points {
      font-weight: 700;
      font-size: 1.25rem;
      display: flex;
      justify-content: space-between;
      margin-right: 0.5rem;

      svg {
        height: 24px;
        width: 24px;
      }
    }

    .reward_items_container {
      padding: 0.5rem 0.5rem;
      
      .categoryDescription {
        background: $mainGray;
        margin: auto;
        padding: 6px 12px;
        border-radius: 5px;
        border: 1px solid gray;
        color: gray;
        font-weight: 700;
        text-align: center;
        font-size: 0.9rem;
        max-width: 90%;
        display: inline-block;
      }
    }

    .disabled {
      background-color: gray;
    }

    .card {
      text-align: left;
      margin: 0.3rem 0;

      .itemDescription {
        font-size: 0.8rem;
      }
      .itemPrice {
        font-size: 0.9rem;
      }
      .itemName {
        font-size: 1rem;
        margin-bottom: 0.25rem;
      }
    }
  }
}

@include desktop {
  .rewards_page{
    flex-direction: row !important;

    .main_container {

      .title {
        font-size: 2rem;
      }

      .notice {
        font-size: 1.3rem;
      }

      .points {
        font-size: 1.4rem;

        .my_points {
          font-size: 1.4rem;
        }
      }
    }
  }
}