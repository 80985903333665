@import "../../global.scss";

.openAdminPortalMessage {
  padding: 1rem;
}

.openAdminPortalMessage .modal-dialog {
  max-width: 80vw !important;
}

.openAdminPortalMessage .modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 1rem;
}

.openAdminPortalMessage .modal-body .body {
  height: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.openAdminPortalMessage .admin_welcome {
  font-size: 2rem;
  text-align: center;
}

.openAdminPortalMessage .admin_message {
  font-size: 1.2rem;
  text-align: center;
}

.admin {
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white !important;

  .admin_title {
    font-size: 1.5rem;
    font-weight: bold;
    height: 34px !important;
    margin: 0.5rem 0;
  }

  .nav-tabs {
    border-bottom: none !important;
  }

  .newOrderMessage {
    position: fixed;
    z-index: 5;
    top: 25%;
    background-color: rgb(211, 211, 211);
    color: black;
    width: 60vw;
    height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 2px solid black;
  }

  .adminTabsContainer {
    display: block;
    width: 98%;
    padding-top: 0;
    background-color: white;
    border-radius: 5px;
    background-color: rgba(245, 245, 245, 0.5);

    .tab-content {
      margin: 0 !important;
    }

    .AdminTabs {
      flex: 4;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 42px !important;

      li {
        display: flex;
        flex: 3;
        border-bottom: 1px solid gray;

        button {
          width: 100%;
          font-size: 1.2rem;
          color: black;
          font-weight: 500;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 0px;
          border-bottom-left-radius: 0px;
          border-top-left-radius: 8px;
          border: 1px solid black;
          padding: 0.2rem 0;
          padding-left: 0.5rem;
          background-color: rgba(214, 214, 214, 0.85);
        }

        .active {
          font-weight: 700;
        }
      }
    }

    .tab-content {
      flex: 17;
      margin: 0 0rem;
      margin-bottom: 1.5rem;
      padding: 0;

      .orders_container {
        // margin: 1rem auto;
        // height: calc(100dvh - 1rem - 86px);
        // max-height: calc(100dvh - 1rem - 86px);
        // overflow: auto;

        .order {
          width: 100%;
          margin-bottom: 0.5rem;

          .order_header {
            background-color: $mainGray;
            border: 1px solid gray;
            padding: 0.25rem 0.5rem;

            .order_head {
              display: flex;
              align-items: center;
              width: 100%;

              .orderNumber {
                color: $mainGreen;
                margin-right: 0.3rem;
                font-size: 1.3rem;
                font-weight: 800;
              }

              .orderType {
                color: $mainGreen;
                font-size: 1.3rem;
                font-weight: 800;
              }

              .orderDetails {
                margin-left: 0.75rem;
                padding-top: 0.1rem;
                font-size: 1.2rem;
                font-weight: 600;
              }
            }

            .complete_order {
              display: flex;
              justify-content: flex-end;
              margin-right: 2rem;
              color: white;
              font-size: 1.2rem;
              font-weight: 700;
              background-color: $mainGreenLight;
              border: 2px solid $mainGreen;
              border-radius: 5px;
              padding: 0.25rem 1rem;
            }

            .collect_payment {
              background-color: rgba(38, 0, 255, 0.6);
              border: 2px solid rgb(38, 0, 255);
            }

            .expand_button {
              height: 30px;
              width: 30px;
              color: $mainGreen;
            }
          }

          .card-body {
            display: flex;
            flex-direction: column;
            align-items: center;
            border: 1px solid gray;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
            padding: 0.5rem 1rem;

            .payLater {
              color: blue;
              font-weight: 700;
              font-size: 36px;
              background-color: $mainGray;
              padding: 0.5rem;
              border: 2px solid gray;
              border-radius: 5px;
            }

            .order_items {
              display: flex;
              flex-direction: column;
              min-height: 6rem;
              width: 96%;

              .content {
                margin: 0;
                font-size: 1.2rem;
              }

              .item {
                padding: 0.5rem 0;
                border-top: dashed 1px rgb(141, 141, 141);

                .reward_points {
                  font-weight: 700;
                  font-size: 1.2rem;
                  display: flex;
                  justify-content: center;
                }

                .left {
                  display: flex;
                  flex-direction: column;

                  .item_name {
                    display: flex;
                    flex-direction: row;
                  }

                  .item_extra {
                    padding-left: 2rem;

                    .special {
                      color: green;
                    }
                  }

                  .specialRequest {
                    font-weight: 700;
                  }
                }

                .right {
                  .content{
                    text-align: right;
                  }
                }
              }

              .item:first-child {
                border-top: none;
              }

              .order_totals {
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: space-between;
                padding: 0.5rem 0;
                border-top: solid 1px black;

                .right {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-end;

                  .price {
                    display: flex;

                    .oldPrice {
                      margin-right: 0.4rem;
                    }
                  }
                }
              }
            }

            .order_footer {
              width: 96%;
              height: 4rem;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 4rem 4rem;
              margin-top: 0.25rem;
              border-top: 1px solid black;

              button {
                border: none;
                color: white;
                font-weight: 800;
                font-size: 1.2rem;
                border-radius: 10px;
                padding: 1.25rem 1.25rem;
                margin: 0 4rem;
              }

              .cancel {
                background-color: rgba(255, 0, 0, 0.75);
                border: 4px solid red;
              }

              .complete {
                background-color: rgba(0, 128, 0, 0.75);
                border: 4px solid green;
              }
            }

            .delivery_address {
              padding: 0.25rem 0;
              margin-bottom: 0.5rem;
              width: 50%;
              background-color: $mainGray;
              border: 1px solid gray;

              .address_label {
                margin-right: 0.25rem;
              }
            }
          }
        }
      }
    }
  }
}

.not_authorized {
  height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.areYouSureModal {
  margin: 10vh 0;

  .buttons {
    display: flex;
    width: 100%;
    height: 20vh;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    button {
      margin: 2rem;
      color: white;
      font-weight: 600;
      font-size: 30px;
      width: 8rem;
      height: 4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
    }

    .yes {
      background-color: green;
    }

    .no {
      background-color: red;
    }
  }
}

.orderHistory_container {
  .no-focus-outline {
    outline: none;
  }

  .filter_by_dates {
    align-items: center;

    .filter_by_dates_submit {
      border: 1px solid black;
      background-color: white;
      color: black;
      font-size: 1.1rem;
      padding: 0.1rem 0.5rem;
      margin-left: 1rem;
      border-radius: 5px;
    }
  }

  .card {
    margin-bottom: 0.75rem;
    border: 1px solid gray;

    .card-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem 1rem;

      .left {
        display: flex;

        .orderNumber {
          font-size: 1rem;
          font-weight: 700;
          color: $mainGreen;
        }

        .orderType {
          font-size: 1rem;
          font-weight: 700;
          color: $mainGreen;
          margin-left: 6px;
        }

        .orderName {
          margin-top: 0.2rem;
          font-size: 0.9rem;
          font-weight: 700;
          margin-left: 6px;
        }

        .total {
          margin-top: 0.2rem;
          font-size: 0.9rem;
          margin-left: 6px;
          font-weight: 700;
        }

        .edited {
          color: orange;
        }

        .cancelled {
          color: red;
        }

        .requestedDate {
          margin-top: 0.2rem;
          font-size: 0.9rem;
          margin-left: 6px;
          font-weight: 700;
        }
      }

      .right {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .status_tag {
          padding: 0.125rem;
          border-radius: 5px;
          color: white;
          font-size: 0.55rem;
          font-weight: 800;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .complete {
          background-color: rgba(0, 128, 0, 0.6);
          border: 2px solid green;
          margin-left: 0.25rem;
        }

        .cancelled {
          background-color: rgba(255, 0, 0, 0.6);
          border: 2px solid red;
          margin-left: 0.25rem;
        }

        .edited {
          background-color: rgba(255, 165, 0, 0.6);
          border: 2px solid orange;
          margin-left: 0.25rem;
        }

        .pay_later {
          background-color: rgba(38, 0, 255, 0.6);
          border: 2px solid rgb(38, 0, 255);
          margin-left: 0.25rem;
        }
      }
    }
  }

  .month_selection {
    display: block;
    margin-bottom: 1rem;

    .year_container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0.5rem;

      .year {
        margin: 0 0.5rem;
        color: black;
        font-size: 1.4rem;
      }

      svg {
        height: 1.2rem;
        width: 1.2rem;
      }
    }

    .month_container {
      display: flex;
      overflow: auto;

      .monthButton {
        flex: 1;
        border: 2px solid gray;
        background-color: $mainGray;
        border-radius: 5px;

        .month {
          .label {
            font-size: 0.9rem;
          }
        }
      }
    }

    .selected {
      background-color: $mainRedLight !important;
      color: white;
      border: 2px solid $mainRed !important;
      font-weight: 700;
    }
  }

  .order_summary {
    margin-bottom: 0.5rem;
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: column;

    .selectedMonth {
      font-size: 1.4rem;
    }

    .numberOfOrders {
      display: flex;
      font-size: 1.2rem;
    }

    .chargedNumbers {
      display: flex;

      .chargedAmount {
        font-size: 1.2rem;
      }

      .rightSide {
        margin-left: 5px;
        padding-left: 5px;
        border-left: 2px solid #000;
      }
    }
  }
}

.store_settings {
  padding-top: 0 !important;

  .locked_container {

    svg {
      height: 40px;
      width: 40px;
      margin-top: 1rem;
    }
    
    .locked {
      background-color: #eeeeee;
      box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
      margin: 1rem;
      display: flex;
      height: 160px;

      .field {
        padding: 0.75rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50%;

        .toggle-button {
          padding: 0.4rem 0.8rem;
          border: none;
          cursor: pointer;
          font-size: 1.2rem;
          border-radius: 5px;
          font-weight: 700;
          margin-top: 4px;
        }

        .toggle-button.active {
          background-color: green;
          color: white;
        }

        .toggle-button.inactive {
          background-color: red;
          color: white;
        }
      }
    }

    .open {
      display: flex;
      height: 160px;

      .field {
        padding: 0.75rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50%;

        .toggle-button {
          padding: 0.4rem 0.8rem;
          border: none;
          cursor: pointer;
          font-size: 1.2rem;
          border-radius: 5px;
          font-weight: 700;
          margin-top: 4px;
        }

        .toggle-button.active {
          background-color: green;
          color: white;
        }

        .toggle-button.inactive {
          background-color: red;
          color: white;
        }
      }
    }
  }

  .orderETA_container {
    min-height: 34vh;

    .order_eta {
      display: flex;
      margin: 1rem 0;

      .ETA {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .ETA_title {
          font-size: 22px;
          margin-bottom: 0.5rem;
        }

        .ETA_inputs {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: 15px;

          .hyphen {
            margin-right: 0.5rem;
          }

          input {
            border: none;
            width: 60px;
            height: 34px;
            margin-right: 0.5rem;
            border-radius: 5px;
            text-align: center;
            border: 1px solid black;
          }
        }
      }
    }

    .submit_container {
      display: flex;
      justify-content: center;
      margin-top: 1rem;

      .submit {
        width: 8rem;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        border-radius: 5px;
        font-weight: 600;
        font-size: 18px;
        border: 2px solid red;
        background-color: $mainRedLight;
      }
    }
  }

  .store_hours,
  .delivery_store_hours {
    width: 100%;
    margin-bottom: 1rem;

    .card-header {
      font-size: 20px;
      font-weight: 700;
    }

    .card-body {
      .day {
        padding: 0.3rem 0;
        display: flex;
        align-items: center;

        .dayofWeek {
          flex: 1.5;
          font-size: 1.1rem;
        }

        .open {
          flex: 2.5;
          display: flex;
          justify-content: center;
          border: 1px solid black;
          border-radius: 5px;
          height: 2rem;
        }

        .open:disabled {
          background-color: $mainGray;
        }

        .close {
          flex: 2.5;
          display: flex;
          justify-content: center;
          border: 1px solid black;
          border-radius: 5px;
          height: 2rem;
        }

        .close:disabled {
          background-color: $mainGray;
        }

        .changeButton {
          flex: 2;
          display: flex;
          justify-content: center;

          button {
            padding: 0.15rem 0.25rem;
            background-color: $mainRedLight;
            border: 2px solid $mainRed;
            border-radius: 5px;
            color: white;
          }

          button:disabled {
            background-color: $mainGray;
            color: gray;
            border: 2px solid gray;
          }
        }
      }

      .submit {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1.5rem;

        button {
          padding: 0.5rem 1rem;
          border: none;
          background-color: $mainColor;
          border-radius: 5px;
          color: white;
          font-size: 18px;
          font-weight: 700;
          border: 2px solid red;
          background-color: $mainRedLight;
        }
      }
    }
  }

  .pickup_hours {
    display: flex;
    flex-direction: column;
    width: 75vw;
    margin: 0 auto;

    .day {
      display: flex;
      height: 6vh;
      margin-bottom: 0.5rem;

      .dayofWeek {
        flex: 1;
        display: flex;
        align-items: center;
        font-size: 1.2rem;
        font-weight: bold;
      }

      .pickup_open {
        flex: 2;
      }

      .to {
        display: flex;
        align-items: center;
      }

      .pickup_close {
        flex: 2;
      }
    }

    .hours_buttons {
      display: flex;
      justify-content: space-around;
      margin: 0.75rem auto;
      width: 50vw;

      .changeHoursButton {
        background-color: rgb(249, 177, 43);
        border: 2px solid rgb(200, 130, 0);
        padding: 0.5rem 1rem;
        color: white;
        border-radius: 4px;
        font-weight: 900;
      }

      .submitHoursButton {
        background-color: rgb(48, 147, 48);
        border: 2px solid rgb(1, 85, 1);
        padding: 0.5rem 1rem;
        color: white;
        border-radius: 4px;
        font-weight: 900;
      }
    }
  }

  .store_closures {
    min-height: 34vh;
    border-radius: 5px;

    .card-body {
      min-height: 40vh;
      display: flex;
      flex-direction: column;
      align-items: center;

      .closures_container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;

        .closures_title {
          font-size: 1.5rem;
          font-weight: 700;
          margin-right: 0.5rem;
        }

        .closures {
          display: block;

          .closure_day_container {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid gray;
            border-radius: 4px;
            margin: 1rem 0.5rem;
            padding: 0.5rem;

            .day {
              font-size: 1.3rem;
              font-weight: 700;
              margin-right: 2rem;
            }

            .x {
              color: red;
              font-weight: 700;
              font-size: 1.6rem;
            }
          }
        }
      }

      .add_closure {
        display: flex;
        align-items: center;
        justify-content: center;

        input {
          height: 2rem;
          border: 1px solid black;
          border-radius: 5px;
        }

        .submit {
          button {
            width: 8rem;
            padding: 0.5rem 0.25rem;
            border-radius: 5px;
            border: none;
            background-color: $mainColor;
            color: white;
            font-weight: 700;
            border: 2px solid red;
            background-color: $mainRedLight;
          }

          .submit_cancel {
            display: flex;
            align-items: center;
            margin: 0 0.5rem;

            .cancel {
              width: 6rem;
              border: none !important;
              background-color: black;
              margin-left: 0.5rem;
            }
          }
        }
      }
    }
  }

  .store_details {
    width: 80%;
    margin: 0 auto;

    .card-header {
      font-size: 20px;
      font-weight: 700;
    }

    .card-body {
      min-height: 34vh;
      border-radius: 5px;

      .input_fields {
        display: flex;
        flex-direction: column;
        margin-bottom: 0.25rem;

        input {
          border: 1px solid black;
          border-radius: 5px;
        }
      }
    }

    .submit {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 1.5rem;

      button {
        padding: 0.5rem 1rem;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 18px;
        font-weight: 700;
        border: 2px solid red;
        background-color: $mainRedLight;
      }
    }
  }

  .store_rewards {
    padding: 0.25rem 0.5rem;

    .card-body {
      min-height: 20vh;
      display: flex;
      flex-direction: column;
      align-items: center;

      .rewards_notice {
        margin-bottom: 0.25rem;
        font-size: 1.1rem;
        font-weight: bold;
        background-color: rgb(213, 213, 213);
        border: 2px solid gray;
        border-radius: 5px;
        padding: 0.25rem 1rem;
        color: rgb(76, 76, 76);
      }

      .rewards_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0 2rem;

        .rewards_toggle {
          display: flex;
          margin-top: 0.5rem;
          margin-bottom: 0.25rem;

          .title {
            margin: 0;
            margin-right: 1rem;
            text-align: center;
            display: flex;
            align-items: center;
            font-weight: bold;
            font-size: 1.5rem;
          }

          .toggle-button {
            display: flex;
            width: 125px;
            height: 40px;
            border-radius: 20px;
            overflow: hidden;
            border: 2px solid #ccc;
            cursor: pointer;
          }

          .toggle-option {
            width: 50%;
            text-align: center;
            line-height: 36px;
            font-weight: bold;
            font-size: 1.4rem;
            color: white;
            transition: all 0.3s ease;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .on {
            background-color: green;
          }

          .off {
            background-color: red;
            display: none;
            /* Initially hide the "Off" side */
          }

          /* Show the "Off" side and hide the "On" side when the button is active */
          .toggle-button.active .on {
            display: none;
          }

          .toggle-button.active .off {
            display: block;
          }
        }

        .add_tier {
          margin-top: 0.5rem;
          margin-bottom: 0.25rem;
          padding: 0.25rem 1rem;
          border: 2px solid red;
          background-color: $mainRedLight;
          border-radius: 5px;
          color: white;
          cursor: pointer;
          font-size: 1rem;
          font-weight: bold;
        }
      }

      .reward_tiers_container {
        width: 100%;
        padding: 0 2rem;

        .reward_tier {
          border: 1px solid gray;
          border-radius: 5px;
          margin-bottom: 0.5rem;
          padding: 0.5rem 1rem;

          .reward_tier_header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .reward_tier_detail {
              display: flex;
              align-items: center;

              .reward_tier_points {
                font-size: 1.3rem;
                font-weight: 800;
              }
            }
          }

          .items_text {
            font-size: 1.1rem;
          }

          .reward_items_container {
            padding: 0rem 2rem;

            .reward_item {
              .item {
                font-size: 1rem;
              }
            }
          }

          .reward_buttons {
            display: flex;

            .button {
              padding: 0.25rem 0.75rem;
              border-radius: 5px;
              color: White;
              text-align: center;
              margin: 0 0.75rem;
              font-size: 1rem;
              cursor: pointer;
              font-weight: bold;
            }

            .edit_reward_tier {
              border: 2px solid rgb(198, 129, 0);
              background-color: rgba(255, 166, 0, 0.75);
            }

            .delete_reward_tier {
              border: 2px solid red;
              background-color: $mainRedLight;
            }
          }
        }
      }
    }
  }

  .AdminSettingsTabs {
    display: flex;
    flex-direction: row;
    width: 100%;

    .nav-item {
      margin-bottom: 1px;
      flex: 1;
      border-top-left-radius: none !important;

      button {
        font-size: 0.9rem;
        width: 100%;
        text-align: center;
        color: black;
        border-bottom: 1px solid black;
        border-radius: 0;
        padding: 0.5rem;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        border: 1px solid black;
        background-color: rgba(214, 214, 214, 0.85);
      }

      .active {
        font-weight: 700;
      }
    }
  }

  .tab-content {
    padding: 0 !important;
  }

  .StoreHoursTabs {
    display: flex;
    justify-content: center;

    .nav-item {
      width: 40%;

      button {
        width: 100%;
        font-size: 1rem;
        color: black;
        border: 1px solid black;
        background-color: rgba(214, 214, 214, 0.85);
        padding: 0.3rem 0;
      }

      .active {
        background-color: rgba(214, 214, 214, 0.85);
        color: $mainColor;
        font-size: 1rem;
        font-weight: 700;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        border-bottom: 1px solid black;
      }
    }
  }
}

.admin_tabs_content {
  display: block;
  padding: 0.5rem;
  border-radius: 8px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border: 1px solid gray;
  border-top: none;
  background-color: white;
  height: calc(100dvh - 1rem - 86px);
  max-height: calc(100dvh - 1rem - 86px);
  overflow: auto;
}

.editRewardItemModal {
  .modal-content {
    background-color: rgb(214, 214, 214);
  }

  .modal-dialog {
    max-width: 60vw !important;
    margin-top: 4rem;

    .modal-title {
      font-size: 1.6rem;
      font-weight: bold;
    }

    .edit_reward_item_container {
      margin-top: 1rem;
      padding: 0 1.5rem;

      .input_fields {
        margin-bottom: 1rem;
        display: flex;
        align-items: center;

        .label {
          font-size: 1.4rem;
        }

        input {
          width: 60%;
          padding: 0.25rem 0.5rem;
          margin: 0.5rem 1rem;
          border: none;
          border-bottom: 1px solid black;
          font-size: 1.4rem;
          cursor: pointer;
        }

        select {
          height: 40px;
          width: 60%;
          max-width: 600px;
          margin-top: 0.25rem;
          border: 1px solid black;
          border-radius: 4px;
          margin: 0 1rem;
          font-size: 1.4rem;
        }
      }

      .dropdown {
        display: flex;
        flex-direction: row;
      }

      .submit {
        max-width: 180px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        border: 2px solid red;
        background-color: $mainRedLight;
        border-radius: 5px;
        padding: 0.25rem 0.75rem;
        margin: 0 auto;
        margin-top: 2rem;
        margin-bottom: 1rem;
        font-size: 1.2rem;
      }
    }
  }
}

.rewardItemModal {
  .modal-dialog {
    max-width: 76vw !important;
    height: 80vh;
    margin-top: 0.5rem !important;

    .modal-content {
      min-height: 80vh;
      max-height: 96vh;

      .modal-header {
        padding: 0.5rem;
      }

      .modal-body {
        padding: 0.5rem 1rem;

        .reward_modal_body {
          height: calc(100dvh - 4dvh - 2rem - 80px);
          max-height: calc(100dvh - 4dvh - 2rem - 80px);
          overflow: auto;
        }
      }
    }
  }

  .modal-title {
    font-size: 1.6rem;
    font-weight: bold;
    height: 40px;
  }

  .add_reward_inputs {
    width: 100%;
    margin: 1rem 0;
    margin-bottom: 0.5rem;

    .input_fields {
      display: flex;
      flex-direction: column;

      label {
        font-size: 1.4rem;
      }

      input {
        padding: 0.25rem 0.5rem;
        margin: 1rem;
        border: none;
        border-bottom: 1px solid black;
        font-size: 1.3rem;
        cursor: pointer;
      }
    }
  }

  .add_tier_button {
    justify-content: center;
    text-align: center;
    border: 2px solid red;
    background-color: $mainRedLight;
    border-radius: 5px;
    width: 40% !important;
    padding: 0.25rem;
    margin: 0 auto;
    color: white;
    font-size: 1.4rem;
    cursor: pointer;
    margin-top: 0.5rem;
  }

  .current_reward_container {
    padding: 0.25rem 0.5rem;

    .reward_title {
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
      font-weight: bold;
    }

    .reward_item {
      margin-bottom: 0.2rem;

      .item {
        border: 1px solid gray;
        border-radius: 5px;
        padding: 0.2rem 0.5rem;
        display: flex;
        justify-content: space-between;

        .item_details {
          display: flex;
          align-items: center;

          .price {
            margin-left: 0.25rem;
          }
        }

        .reward_item_options {
          display: flex;

          .button {
            padding: 0.2rem 0.5rem;
            border-radius: 5px;
            color: White;
            text-align: center;
            margin: 0 0.5rem;
            font-size: 1rem;
            cursor: pointer;
          }

          .edit {
            border: 2px solid rgb(198, 129, 0);
            background-color: rgba(255, 166, 0, 0.75);
          }

          .delete {
            border: 2px solid red;
            background-color: $mainRedLight;
          }
        }
      }
    }
  }

  .add_new_reward_items_container {
    padding: 0.25rem 0.5rem;
    margin-top: 1rem;

    .reward_title {
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
      font-weight: bold;
    }

    .reward_items_container {
      .add_discount_container {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin: 0.75rem 0;

        .header_container {
          display: flex;

          .add_discount_header {
            font-size: 1rem;
          }

          input {
            font-size: 1rem;
            border: none;
            border: 1px solid black;
            border-radius: 5px;
            margin-left: 0.5rem;
            padding: 0.1rem 0.5rem;
            padding-right: 3rem;
          }

          .submit {
            border: 2px solid red;
            border-radius: 5px;
            background-color: $mainRedLight;
            color: white;
            padding: 0.1rem 0.75rem;
            font-size: 1rem;
            margin-left: 1.5rem;
            cursor: pointer;
          }
        }
      }

      .reward_item {
        margin: 0 auto;
        margin-bottom: 0.25rem;
        border: 1px solid gray;
        border-radius: 5px;

        .header_container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 0.75rem;
          cursor: pointer;

          .header {
            font-size: 1.1rem;
            cursor: pointer;
          }

          .button {
            font-size: 1.8rem;
          }
        }

        .item_container {
          display: flex;
          align-items: center;
          padding: 0 0.5rem;
          border: 1px solid gray;
          border-radius: 5px;
          margin: 0.5rem 0;

          .add_button {
            border: 2px solid red;
            background-color: $mainRedLight;
            border-radius: 5px;
            padding: 0.25rem 0.75rem;
            color: white;
            cursor: pointer;
          }

          .item_name_container {
            display: flex;

            .name {
              font-size: 1rem;
            }

            .size {
              margin-left: 0.35rem;
              font-size: 1rem;
            }

            .price {
              margin-left: 0.5rem;
              font-size: 1rem;
            }
          }
        }

        .reward_item,
        .header_container {
          border: none;
          margin: 0 0.5rem;

          .item_name_container {
            width: 96%;
            margin: 0.5rem auto;
          }
        }

        .reward_item:has(.header_container) {
          border: 1px solid gray;
          border-radius: 5px;
          width: 96%;
          margin: 0 auto;
          margin-bottom: 0.2rem;
        }
      }
    }
  }

  .new_items_container {
    margin: 0.5rem 0;

    .new_items_button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.25rem 0.5rem;
      width: 50%;
      margin: 0 auto;
      border: 2px solid red;
      background-color: $mainRedLight;
      border-radius: 5px;
      color: white;
      font-size: 1.1rem;
      font-weight: 700;
      cursor: pointer;
    }
  }
}

@include desktop {
  .orderETA_container {
    .ETA_inputs {
      font-size: 18px !important;

      .hyphen {
        margin-right: 0.75rem !important;
      }

      input {
        width: 80px !important;
        height: 40px !important;
        margin-right: 0.75rem !important;
      }
    }
  }
}

@include phone {
  .payLater {
    font-size: 26px !important;
  }

  .order_summary {
    padding: 0.25rem !important;

    .chargedNumbers {
      flex-direction: column;
    }

    .rightSide {
      padding: 0 !important;
      margin: 0 !important;
      border: none !important;
    }
  }

  .openAdminPortalMessage {
    padding: 1rem;
  }

  .openAdminPortalMessage .modal-body {
    padding: 1rem !important;
  }

  .openAdminPortalMessage .modal-dialog {
    max-width: 100vw !important;
    height: 40vh !important;
  }

  .openAdminPortalMessage .admin_welcome {
    font-size: 2rem;
    text-align: center;
  }

  .openAdminPortalMessage .admin_message {
    font-size: 1rem;
    text-align: center;
  }

  .openAdminPortalMessage .modal-body .body {
    height: 200px;
  }

  .adminTabsContainer {
    display: block !important;
    padding: 0.5rem !important;

    .AdminTabs {
      display: flex !important;
      align-items: center;
      justify-content: center;
      margin-right: 0 !important;

      .nav-item {
        button {
          font-size: 0.9rem !important;
          text-align: center !important;
          border-bottom-right-radius: 0px !important;
          border-top-left-radius: 8px !important;
        }
      }
    }

    .tab-content {
      padding: 0 !important;
    }
  }

  .month_selection {
    overflow-x: auto;
    overflow-y: hidden;
  }

  .week_selection {
    overflow-x: auto;
    overflow-y: hidden;
  }

  .weekButton {
    font-size: 0.7rem !important;
  }

  .store_settings {
    display: flex;
    flex-direction: column;

    .tab-content {
      margin: 0;
      padding: 0;
      width: 100%;
    }

    .AdminSettingsTabs {
      display: flex !important;

      li {
        button {
          font-size: 0.85rem;
          padding: 0.3rem;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
        }
      }
    }

    .orderETA_container {
      .order_eta {
        display: block;
      }
    }
  }

  .StoreHoursTabs {
    button {
      font-size: 1rem !important;
    }
  }

  .store_hours,
  .delivery_store_hours {
    .hours {
      .day {
        flex-direction: column;
        border-bottom: 1px solid gray;
      }
    }
  }
}

@include desktop {
  .left {
    .orderNumber {
      font-size: 1.4rem !important;
    }

    .orderType {
      font-size: 1.4rem !important;
    }

    .orderName {
      font-size: 1.2rem !important;
    }

    .total {
      font-size: 1.2rem !important;
    }

    .requestedDate {
      font-size: 1.2rem !important;
    }
  }

  .right {
    .status_tag {
      font-size: 1rem !important;
    }
  }

  .order_footer {
    width: 96% !important;
    height: 4rem !important;
    padding: 4rem 4rem !important;
    margin-top: 0.25rem !important;

    button {
      font-size: 1.5rem !important;
      padding: 1.25rem 1.25rem !important;
    }
  }
}