@import "../../global.scss";

.itemModal {
  overflow-y: hidden;

  .modal-dialog {
    margin: auto;
    .modal-content {
      height: 100dvh;

      .modal-body {
        height: 100%;
        padding: 1rem 0.5rem;
        padding-top: 0.5rem;
        border: 1px solid black;

        .modal-title {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          height: 5.5dvh !important;

          .itemModal_title {
            font-weight: 700;
          }

          svg {
            color: red;
            height: 2rem;
            width: 2rem;
          }
        }

        .itemModal_body {
          display: flex;
          flex-direction: column;
          align-items: center;
          background-color: #e6e6e6;
          border-radius: 5px;
          border: 1px solid black;
          height: 76dvh !important;
          padding: 0.5rem 0.5rem;
          overflow-y: auto;
          margin: 0.25rem 0;

          .itemModal_name {
            font-size: 1.5rem;
          }

          .itemModal_optionsSection {
            width: 100%;
            margin-top: 0.5rem;
            border: 1px solid black;
            border-radius: 7px;

            .required_tab {
              font-size: 0.8rem;
              border: 2px solid gray;
              border-radius: 5px;
              background-color: $mainGray;
              color: black;
              padding: 0rem 0.25rem;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 0.3rem;
            }
          }

          .itemModal_header {
            border-bottom: 1px solid black;
            border-bottom-left-radius: 7 !important;
            border-bottom-right-radius: 7 !important;
            color: black;
            background-color: #c7c5c5;
            padding: 0.2rem 0.5rem;

            .expand_collapse_button {
              font-size: 24px;
            }

            .header {
              font-size: 16px;
            }
          }
          .itemModal_header.no-bottom-radius {
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
          }

          .pre_selected {
            background-color: $mainRedLight !important;
            border: 3px solid $mainRed !important;
            color: white;
            background-color: #c7c5c5;
            padding: 0.5rem;
            margin-top: 0.5rem;
          }

          .itemModal_specialRequests {
            .itemRequests_input {
              border: 1px solid black;
              border-radius: 5px;
            }
          }

          .description {
            font-size: 1.1rem;
          }

          .itemModal_quantity {
            align-items: center;

            .quantity {
              margin-bottom: 0;
              font-size: 1.3rem;
            }
            .current_number {
              font-size: 1.5rem;
            }
            .sub {
              svg:hover {
                color: red;
              }
            }
            .add {
              svg:hover {
                color: red;
              }
            }
          }

          .itemModal_options {
            padding: 0.25rem;

            .extra_code_container {
              justify-content: space-between;
              margin: 0 0.75rem 0 0.25rem;
              display: flex;
              align-items: center;

              .required_tab {
                font-size: 0.7rem;
                border: 2px solid gray;
                border-radius: 5px;
                background-color: $mainGray;
                color: black;
                padding: 0rem 0.25rem;
              }
            }

            .extraOptions {
              .option_choice_pizza {
                border-radius: 5px;
                border: 1px solid black;
                div {
                  font-size: 0.9rem;
                }
              }
              .option_choice {
                border-radius: 5px;
                padding: 0.425rem 0;
                border: 1px solid black;

                div {
                  font-size: 0.9rem;
                }
              }
              .options {
                .FirstHalf {
                  border: 2px double #000;
                  border-radius: 150px 0 0 150px;
                  width: 1.5rem;
                  cursor: pointer;
                }
                .Full {
                  border: 2px double #000;
                  border-radius: 100%;
                  width: 2rem;
                  cursor: pointer;
                }
                .SecondHalf {
                  border: 2px double #000;
                  border-radius: 0 150px 150px 0;
                  width: 1.5rem;
                  cursor: pointer;
                }
                .Active {
                  background-color: $mainRedLight;
                }
              }
              .Active {
                background-color: $mainRedLight;
                border: $mainRed solid 2px;
                color: white;
              }
            }

            .Active {
              background-color: $mainRedLight !important;
              border: 3px solid $mainRed !important;
              color: white;
            }
          }
        }

        .itemModal_Submit {
          height: 15dvh !important;
          display: flex;
          flex-direction: column;

          .itemModal_totalPrice {
            font-size: 1.1rem;
            padding: 0.1rem;
            background-color: $mainGray;
            border-radius: 5px;
            border: 2px solid black;
            margin: 0.25rem 0;
            display: flex;
            justify-content: center;
            color: black;
            font-weight: 800;
            height: 26%;
            align-items: center;
          }

          button {
            color: white;
            font-size: 1.4rem;
            font-weight: 700;
            width: 100%;
            background-color: $mainGreenLight;
            border-radius: 10px;
            border: 8px solid $mainGreen;
            height: 66%;
          }
        }
      }
    }
  }
}

.request_notice {
  font-size: 10px;
}
