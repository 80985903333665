@import "../../global.scss";

.hoursModal {
  .HoursTabs {
    margin-top: 1rem;
    width: 100%;
    .nav-item {
      width: 50%;
      button {
        width: 100%;
        font-size: 1.1rem;
        color: black;
      }
      .active {
        font-weight: 700;
        color: $mainColor;
      }
    }
  }
  .tab-content {
    width: 100%;
  }
  .modal-dialog {
    .modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;

      .modal-header {
        width: 100%;
        justify-content: end;
        padding: 0.5rem;

        svg {
          color: red;
          height: 2rem;
          width: 2rem;
        }
      }

      .hoursModal_title {
        font-size: 32px;
        margin: 0.75rem 0 1rem 0.75rem;
        width: 100%;
      }

      .card {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;
        padding: 0.75rem 0.25rem;
        border: 1px solid gray;
        border-radius: 5px;
      }

      .store_status {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 26px;
        font-weight: 700;
        padding: 0.5rem 0;

        .open {
          color: green;
        }
        .closed {
          color: red;
        }
      }

      .hoursModal_container {
        width: 98%;
        background-color: rgb(240, 240, 240);
        border-radius: 5px;
        padding: 0.5rem 0.75rem;
        border: 1px solid gray;

        ul {
          list-style-type: none;
          margin-block-start: 0;
          margin-block-end: 0;
          margin-inline-start: 0px;
          margin-inline-end: 0px;
          padding-inline-start: 0px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          li {
            padding: 0.5rem 0;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: start;

            .day {
              display: flex;
              flex: 1;
              align-items: flex-start;
              justify-content: flex-start;
              font-size: 18px;
            }
            .time {
              display: flex;
              flex: 4;
              align-items: center;
              justify-content: flex-end;
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}
