@import "../../global.scss";

.navbar {
  padding: 0;
  z-index: 12;
}

.navbar_home {
  height: 100px;
  border-bottom: 1px solid rgb(12,52,108);

  .topbar {
    height: 100%;
    background-color: white;

    .left {
      .mylogo {
        height: 88px;
        padding: 0.25rem 1rem;
      }
    }
    .right {
      color: rgb(12,52,108);

      .desktop_nav {
        display: flex;

        .container-fluid {
          margin: 0 !important;
        }
        
        ul {
          display: flex;

          li {
            padding: 0.5rem 1.5rem !important;
            font-weight: 500;
            margin: 0 0.75rem;
            text-align: center;
            font-size: 1.2rem !important;
            border: none !important;

            a {
              text-decoration: none !important;
              color: rgb(12,52,108);
            }
          }
          li:hover {
            font-weight: 700;
            //border-bottom: 1px solid rgb(12,52,108) !important;
          }
        }
      }
      .mobile_nav {
        display: none;
      }
    }
  }
}

.topbar {
  padding: 0 0.75rem;
  height: 60px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid white;
  background-color: black;
  color: white;

  .left {
    font-weight: 600;
    font-size: 1rem;

    cursor: pointer;

    a {
      color: white;
      text-decoration: none;

      .store_name {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 0;
      }
    }
  }

  .right {
    cursor: pointer;
    align-items: center;
    justify-content: center;
    z-index: 12;

    svg {
      height: 50px;
      width: 50px;
      padding: 5px;
    }

    .dropdown {
      position: absolute;
      width: calc(100%);
      left: 0vw;
      display: flex;
      justify-content: center;
      background-color: black;
      z-index: 12;

      .container-fluid {
        z-index: 12;
      }

      ul {
        .nav_item {
          list-style-type: none;
          padding: 15px 0px;
          font-size: 1rem;
          font-weight: 700;
          padding-left: 1rem;
          color: white;
          text-decoration: none;
          display: list-item;
          text-align: -webkit-match-parent;
          cursor: pointer;
        }
        // li{
        //     padding-left: 1rem;
        //     font-weight: 700;

        //     a{
        //       text-decoration: none;
        //       color: white;
        //     }
        // }

        li:first-child {
          border-top: 1px solid white;
        }
        li:last-child {
          border: none !important;
        }
      }
    }
  }
}

.hover::after {
  content: '';
  width: 0px;
  height: 2px;
  display: block;
  background: rgb(21, 68, 135);
  transition: 300ms;
}

.hover:hover::after {
  width: 100%;
}

.welcome {
  background-color: $mainColorLight;
  padding-top: 0 !important;

  img {
    width: 150px;
  }

  .discount {
    justify-content: center;
    margin: 0 auto;

    .message {
      font-size: 0.9rem;
    }
    .endDate {
      font-size: 0.8rem;
    }
  }
  .address_container {
    margin: 0 auto;
    font-size: 0.9rem;
    font-weight: 700;
    text-align: center;
    max-width: 90%;

    .store_address {
      margin-bottom: 0;
      font-size: 14px;
      font-weight: bold;
    }
  }
}

@include desktop {
  .navbar {
    padding: 0;
    .topbar {
      height: 90px;

      .left {
        a {
          font-size: 1.3rem;

          .store_name {
            font-size: 1.75rem;
            padding: 1rem 0.75rem;
          }
        }
      }
      .right {
        svg {
          height: 60px;
          width: 60px;
          padding: 5px;
        }

        .nav_item {
          font-size: 1.1rem !important;
          padding-left: 2.25rem !important;
        }
      }
    }

    .welcome {
      img {
        width: 260px !important;
      }

      .discount {
        width: 30%;
        margin: 0 auto;
        .message {
          font-size: 1.15rem;
        }
      }
      .address_container {
        font-size: 1.45rem !important;

        .store_address {
          font-size: 20px;
        }
      }
    }
  }  
}

@include phone {
  .navbar_home {
    height: 90px;

    .topbar {
      .left {
        .mylogo {
          height: 70px !important;
          padding: 0.25rem 1rem;
        }
      }
      .right {
        .desktop_nav {
          display: none;
        }
        .mobile_nav {
          display: flex;

          svg {
            color: rgb(12,52,108);
            height: 44px;
            width: 44px;
          }
  
          .dropdown {
            background-color: rgb(12,52,108);
            top: 90px;

            ul {
              li {
                a {
                  text-decoration: none !important;
                  color: white;
                }
              }
            }
          }
        }
      }
    }
  }
}
